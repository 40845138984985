// Main Container - Master Template
#main {
    @include clearfix;
    float: left;
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    background: $white;
    #content {
        @include grid-column(12, 0);
    }
    .full-width#content {
        @include grid-column-collapse;
    }
}

.page-content {
    @include grid-column(12);
    @include clearfix;
    ul {
        list-style: none;
        margin-left: 1.25rem;
        li {
            font-weight: 600;
            &:before {
                content: "\f0da";
                margin-right: 0.625rem;
                font-family: fontAwesome;
                color: $secondary-color;
                font-size: 1.25rem;
            }
        }
    }

    p img {
        display: block;
        margin: 0 auto 1.875rem;
        border: 4px solid white;
        box-shadow: $dark-gray 0 2px 4px;
        float: none;

        @include breakpoint(medium up) {
            display: inline-block;
            margin: 0 0.9375rem 1.25rem;
            float: right;
        }
    }
}

// General Content Form Container
.full-width--has-form {
    @include grid-row;
    h4 {
        padding-left: 0.9375rem;
        @include clearfix;
        font-size: 1.125rem;
        font-weight: 700;
    }
    .form-container {
        @include grid-column-collapse;
    }
    form {
        padding-top: 0.9375rem;
        padding-bottom: 0.9375rem;
        border-radius: 5px;
    }
}


/* ----------------------------------
Page-specific Styles
---------------------------------- */

// Contact Us
.contact-us {
    h1 {
        @include grid-column-uncollapse(1.875rem);
    }
}

// Sell Material
.sell-material-handling-equipment {
    h1 {
        @include grid-column-uncollapse(1.875rem);
    }
}

// About Greyson
.about-us {
     p img {
        display: block;
        margin: 0 auto 1.875rem;
        border: 4px solid white;
        box-shadow: $dark-gray 0 2px 4px;
        float: none;

        @include breakpoint(medium up) {
            display: inline-block;
            margin: 15px;
            float: right;
        }
    }
    .page-content {
        margin-bottom: 3.125rem;
        h1 {
            padding: 0 !important;
        }
        p {
            @include grid-column(12);
            @include breakpoint(medium up){
                @include grid-column-collapse;
            }
        }
    }
}

// Get Directions {
.get-directions {
    iframe:not(#livechat-compact-view) {
        display: block;
        width: 100%;
        height: 480px;
        margin-bottom: 1.875rem;
        border: 1px solid $light-gray !important;
        border-radius: 5px;
        overflow: hidden;
    }
}

// Sitemap
.site-map {
    .site-map--message {
        margin-bottom: 1.875rem;
        background: $secondary-color;
        padding: 1.875rem 0;

        h1, p {
            color: $white;
        }

        h1 {
            font-size: 2.5rem;
            margin-bottom: 0;
        }

        p {
            font-size: 1.25rem;
        }
    }

    .page-content {
        margin-bottom: 1.875rem;
        ul {
            margin-bottom: 1.875rem;
            list-style: none;

            li {
                padding: 0.625rem 0;
                border-bottom: 1px solid $light-gray;

                &:last-child {
                    border: none;
                }
            }
        }
    }

    h2 {
        font-size: 1.5rem;
        font-weight: 700;
        font-family: $secondary-font;
    }

    .site-map--list {
        list-style: none;

        li {
            padding: 0.375rem 0;
        }
    }
}
