/* ------------------------------------
Homepage Styles
----------------------------------- */

.hero {

    display: none;

    @include breakpoint(medium only) {
        height: auto;
    }

    @include breakpoint(medium) {
        display: block;
        @include clearfix;
        width: 100%;
        height: 360px;
        overflow: hidden;
    }

    @include breakpoint(large up) {
        height: 550px;
    }

    &--slider {
        display: block;
        @include clearfix;
        float: left;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .slick-list, .slick-track {
            max-height: 100%;
        }
    }

    &--slider--contents {
        float: left;
        background: none;

        @include breakpoint(medium only) {
            padding: 3rem 1.25rem;
        }

        //Tablets
        @include breakpoint(medium up) {
            padding-top: 6.5rem;
            padding-bottom: 3.5rem;
            padding-right: 1.25rem;
            text-align: right;
            height: 100%;
            width: 100%;
            background: $light-gray url(../../img/greyson-home-hero-bg.png) top left no-repeat;
            background-size: cover;
            border-bottom: 1px solid darken($light-gray, 10%);
        }

        h2 {
            @include grid-column-offset(5);
            font-size: 1.875rem;
            font-family: $secondary-font;
            line-height: 1.2;

            @include breakpoint(large up) {
                @include grid-column-offset(5);
                font-size: 3.625rem;
                margin-bottom: 1.25rem;
            }
        }

        p {
            @include grid-column-offset(7);
            color: $dark-gray;
            font-size: 0.875rem;
            line-height: 1.4;

            @include breakpoint(large up) {
                margin-bottom: 1.75rem;
                font-size: 1.125rem;
            }
        }

        a[class*="button--"] {
          display: block;
          float: right;
          clear: both;
        }

        .button--shop, &.button--shop:hover {
            @extend button;
            margin-right: 0.625rem;
            border: 3px solid $secondary-color;
            background: $white !important;
            color: $secondary-color !important;

            @include breakpoint(medium) {
              margin-right: 0;
              margin-bottom: 0.9375rem;
            }

            @include breakpoint(large up) {
                font-size: 1.5rem;
            }
        }
    }
}

// Main Content
main {
    @include grid-row;
    @include clearfix;

     @include breakpoint(large up) {
        margin-bottom: 2rem;
    }

    .main-content {
        @include grid-column;
        padding: 1.25rem 0 0;

        @include breakpoint(medium up) {
            padding: 0;
        }

        // Panels
        .panels {
            @include clearfix;
            @include grid-column;
            margin-top: 1.875rem;
            padding: 0;

            @include breakpoint(medium up) {
                margin-top: 0.625rem;
                padding: 1.25rem 0 0;
            }

            & [class*="panels--"] {
                @include grid-column;
                @include clearfix;
                text-decoration: none;

                @include breakpoint(medium) {
                    @include grid-column(4);
                    min-height: 215px;
                }

                & > article {
                    margin-bottom: 1.25rem;
                    padding: 0.75rem;
                    background-color: $secondary-color;
                    color: $white;
                    min-height: 215px;
                    border-radius: 5px;

                    @include breakpoint(medium) {
                        background-color: $light-gray;
                        border: 1px solid $medium-gray;
                        color: $dark-gray;


                        &:hover {
                            background-color: lighten($light-gray, 5%);
                        }
                    }

                    @include breakpoint(large up) {
                         padding: 1.25rem;
                    }

                    h2 {
                        display: block;
                        margin-bottom: 0;
                        font-size: 2.25rem;
                        font-family: $secondary-font;
                        text-transform: uppercase;
                        color: $white;
                        line-height: 1;

                        @include breakpoint(medium) {
                            color: $dark-gray;
                            font-size: 1.75rem;
                        }

                        @include breakpoint(large up) {
                            margin-bottom: 0.625rem;
                            font-size: 2.5rem;
                            text-shadow: 1px 1px 1px $white;
                        }
                    }

                    p {
                        margin-top: 0.9375rem;
                        line-height: 1.4;

                        // Tablet
                        @include breakpoint(medium) {
                            margin-top: 0.9375rem;
                            font-size: 0.875rem;
                        }

                        // Desktop
                        @include breakpoint(large up) {
                            margin-top: auto;
                            padding-right: 2.25rem;
                            font-size: 1rem;
                            text-shadow: 1px 2px 1px $white;
                        }
                    }

                    .button {
                        @include breakpoint(medium) {
                            background: linear-gradient(to bottom, $primary-color, darken($primary-accent-color, 30%),  $primary-color);
                            border: 1px solid $primary-accent-color;
                            font-size: 0.875rem;
                        }
                    }
                }
            }

            .panels--buy > article {
                background: $secondary-color url(../../img/buy-panel-bg.png) top right no-repeat;
                background-size: cover;

                @include breakpoint(large up) {
                    background: $light-gray url(../../img/buy-panel-lg-bg.png) top right no-repeat;
                    background-size: cover;
                }
            }

            .panels--sell > article {
                background: $secondary-color url(../../img/sell-panel-bg.png) top right no-repeat;
                background-size: cover;

                @include breakpoint(large up) {
                    background: $light-gray url(../../img/sell-panel-lg-bg.png) top right no-repeat;
                    background-size: cover;
                }
            }

            .panels--lessors > article {
                margin-bottom: 0;
                background: $secondary-color url(../../img/lessors-panel-bg.png) top right no-repeat;
                background-size: cover;

                @include breakpoint(large up) {
                    background: $light-gray url(../../img/lessors-panel-lg-bg.png) top right no-repeat;
                    background-size: cover;
                }
            }
        }

        // Manufacturer Slider
        .mfg-slider {
            @include grid-column;
            @include clearfix;
            padding: 1.25rem 0;

             @include breakpoint(large up) {
                margin-bottom: 2rem;
            }

            h4 {
                display: block;
                margin-bottom: 0.625rem;
                padding-bottom: 0.125rem;
                font-weight: 400;
                font-size: 1rem;
                font-family: $primary-font;
                color: $dark-gray;
                border-bottom: 1px solid $light-gray;
            }

            .mfg-slider--slides {
                display: block;
                margin-bottom: 0;
                overflow: hidden;
                width: 100%;

                img {
                    padding: 0;
                    max-width: initial;
                    width: auto;

                    @include breakpoint(medium) {
                        padding: 0;
                    }

                    @include breakpoint(large up) {
                          padding: 0;
                    }
                }
            }
        }

        // RTE Content from Apoxe
        .rte-content {
            @include grid-column;
            padding: 0;

            @include breakpoint(large up) {
                @include grid-column(6);
            }
        }

        // Newsletter Block
        #newsletter {
            @include grid-column;
            display: block;
            padding: 0.375rem 0.875rem 0;
            background: #e3e8e8;
            border-radius: 5px;

            @include breakpoint(medium only) {
                padding-top: 0.9375rem;
                padding-bottom: 0.9375rem;
                border: 1px solid #d7d7d7;
                border-radius: 5px;
            }

            @include breakpoint(large) {
                @include grid-column(5);
                @include grid-column-offset(1);
                background: none;
                padding: 0 0 1.25rem;
                border-left: 1px solid $light-gray;
                border-radius: 0;
            }

            p {
                @include grid-column;
                text-align: center;
                font-size: 18px;
                font-family: $primary-font;
                font-weight: 700;
                text-transform: uppercase;
                color: #fe980e;

                @include breakpoint(medium) {
                    @include grid-column;
                    margin: 0.5rem 0 1.25rem;
                    font-size: 1.25rem;
                }

                @include breakpoint(large up) {
                    @include grid-column;
                    margin-top: 0;
                    font-size: 1.625rem;
                }
            }

            .note {
                display: none;
                font-size: 0;
                text-indent: -9999rem;
            }

            fieldset {
                @include grid-column(9, 0);
                @include grid-column-collapse;
                margin: 0;
                padding: 0;

                @include breakpoint(medium only) {
                    @include grid-column(10,0);
                }

                p {
                    display: none;
                    font-size: 0;
                    text-indent: -9999rem;
                }

                ol li {
                    margin: 0;
                    padding: 0;
                    width: 100%;
                }

                input[type="email"] {
                    @include grid-column;
                    display: block;
                    height: 40px;
                    border: 3px solid #d7d7d7;
                    border-right: none;
                    outline: 0;
                    border-radius: 5px 0 0 5px;

                    &:focus {
                        box-shadow: none;
                        outline: 0;
                    }
                }

                ::-webkit-input-placeholder {
                    font-weight: 400;
                    font-size: 0.875rem;
                    color: #231f20;
                }
            }

            button {
                @include grid-column(3);
                display: block;
                margin: 0;
                height: 40px;
                line-height: 1;
                background: #fe980e;
                color: #FFF;
                font-size: 1rem;
                font-family: $primary-font;
                text-align: center;
                text-transform: uppercase;
                border: 3px solid #d7d7d7;
                outline: 0;
                box-shadow: none;
                border-radius: 0 5px 5px 0;

                @include breakpoint(medium only) {
                    @include grid-column(2);
                }

                 @include breakpoint(large up) {
                    @include grid-column(3);
                }
            }
        }
    }
}
