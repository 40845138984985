*,
html {
    box-sizing: border-box;
}
a {
    color: $primary-color;
}
a:hover {
    text-decoration: underline;
}

header,
section,
footer,
main {
    display: block;
}

blockquote {
    border: 1px solid $light-gray;
    padding: 0.9375em;
    position: relative;
    box-shadow: 0px 1px 3px $light-gray;
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 6px;
        height: 100%;
        background: $primary-color;
    }
}

body {
    margin: 0;
    padding: 0;
    font-family: $primary-font;
    color: $medium-gray;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

img {
    max-width: 100%;
}

h1 {
    @include grid-column(12);
    @include clearfix;
    display: block;
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
    font-family: $secondary-font;
    color: $dark-gray;
    font-weight: 700;

    @include breakpoint(small only) {
       font-size: 2rem !important;
       line-height: 1.4;
       text-align: left;
    }

    @include breakpoint(medium) {
        font-size: 2rem;
        line-height: 1.4;
        margin-bottom: 2.5rem;
        text-align: left;
    }

    @include breakpoint(large) {
        @include grid-column-collapse;
        margin-bottom: 1.25rem;
    }
}

p {
    @include clearfix;
    margin-botom: 1.25rem;
}

h2,
h3,
h4,
h5,
h6 {
    color: $dark-gray;
    font-weight: bold;
    font-family: $primary-font;
}

main {
    @include grid-row;
    padding: 20px 0.9375rem;
    @include breakpoint($global-width up) {
        padding: 20px 0;
    }
}

.container {
    @include grid-row;
}

.access {
    display: none;
}

.hidden {
    visibility: hidden;
}

.mobile {
    @include breakpoint(medium up) {
        display: none;
    }
}

.desktop {
    display: none;
    @include breakpoint(medium up) {
        display: block;
    }
}

.related {
    padding: 20px 0.9375rem;
    box-shadow: $inset-box-shadow;
}


 .callout {
        @include clearfix;
        @include callout-base;
        padding: 0.625rem 1.25rem;
        margin-bottom: 1.875rem;
        background: $secondary-color;
        border: 1px solid darken($secondary-color, 10%);
        line-height: 1.2;
        font-size: 1.25rem;
        font-family: $secondary-font;
        color: $white;
        text-align: center;
        border-radius: 5px;
        @include breakpoint(medium up) {
            text-align: left;
        }
        & > span {
            display: block;
            margin: 0 auto 0.625rem !important;
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 1;
            font-family: $primary-font;
            text-transform: uppercase;
            letter-spacing: -1px;

            @include breakpoint(medium up) {
                display: block;
                margin: 0 !important;
                letter-spacing: normal;
                font-family: $secondary-font;
                font-size: 1.875rem;
            }
        }
        &:after {
            content: "";
            @include breakpoint(medium up) {
                content: "\f10b";
                position: absolute;
                top: 0;
                right: 2rem;
                margin: 0;
                padding: 0;
                line-height: 1;
                height: 100%;
                font-size: 4.5rem;
                text-align: center;
                font-family: fontAwesome;
                transform: rotate(10deg);
                -webkit-transform: rotate(10deg);
                -moz-transform: rotate(10deg);
            }
        }
    }

