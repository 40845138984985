.contact {

    h1 {
        @include grid-column(12);
        display: block;
        margin-top: 1.875rem;
        margin-bottom: 0;
        font-size: 2rem;
        text-align: center;
        font-family: $secondary-font;
        color: $dark-gray;

        @include breakpoint(medium up) {
            margin-bottom: 2.5rem;
            text-align: left;
        }
    }
}

.contact-wrapper--map {
    display: none;

    // Tablet Up
    @include breakpoint(medium up) {
        display: block;
        position: relative;
        width: 100%;
        background: $light-gray;
        border-top: 1px solid darken($light-gray, 10%);
        text-align: center;
    }

    iframe {
        display: block;
        vertical-align: top;
        width: 100%;
        height: 360px;
    }
}

.contact-wrapper--info {
    @include grid-column(12);
    padding: 1.875rem 1.5rem;
    text-align: left;

    @include breakpoint(medium) {
        padding-top: 0;
        @include grid-column(4);
    }

    // Desktops
    @include breakpoint(large up) {
        @include grid-column(3);
        padding-top: 0;
    }

    address {
        font-style: normal;
        font-size: 1rem;
        line-height: 1.5;

        hr {
            display: block;
            border: 0;
            border-bottom: 1px solid $light-gray;
        }

        div[itemprop="name"] {
            font-weight: 700;
            font-family: $primary-font;
            color: $secondary-color;
            font-size: 1.125rem;
            font-style: normal;
            line-height: 2;
        }

        span[itemprop="streetAddress"] {
            display: block;
        }

        span.directions {
            display: inline-block;
            margin-top: 0.25rem;
            background: $primary-color;
            border-radius: 3px;

            a {
                padding: 0.375rem 0.625rem;
                color: white;
                text-decoration: none;

                .fa {
                    margin-right: 0.375rem;
                    color: $primary-accent-color;
                }
            }
        }

        span[itemprop="email"] {
            display: block;
            margin-top: 1.25rem;

             .fa {
                 margin-right: 0.9375rem;
                 color: $secondary-color;
             }

             a {
                color: $primary-color;
             }
        }

        .fa {
            margin-right: 0.9375rem;
            color: $secondary-color;
            text-align: center;
        }

        .telephone {
            display: block;
            padding: 0.25rem 0 0;
            color: $dark-gray;
            font-weight: 700;
            text-decoration: none;
        }

        .hours-of-operation {
            display: block;
            margin-top: 1rem;

            dt {
                margin: 0;
                padding: 0;
                color: $dark-gray;
            }

            dd {
                display: block;
                margin-bottom: 0.625rem;
            }
        }
    }
}

.contact-wrapper--content {
    @include grid-column(12);
    margin-bottom: 1.25rem;
    padding-top: 0.25rem;

    // Tablet
    @include breakpoint(medium) {
        @include grid-column(8);
        border-left: 1px solid $light-gray;
        padding-bottom: 3.125rem;
    }

    // Desktop
    @include breakpoint(large up) {
        @include grid-column(9);
    }

    p {
        text-align: center;

        @include breakpoint(medium) {
            text-align: left;
        }

        @include breakpoint(medium up) {
            padding-left: 1.25rem;
        }
    }

    .contact-wrapper--form {
        @include grid-column(12);
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        background: $light-gray;
        border: 1px solid darken($light-gray, 10%);

        @include breakpoint(medium) {
            padding: 0;
            background: none;
            border: none;
        }

        label {
            font-weight: 700;
        }

        button.button {
            display: block;
            margin: 0 0.625rem;
            font-size: 1.125rem;
            width: calc(100% - 1.25rem);
            height: 50px;
            border-radius: 5px;

            @include breakpoint(medium up) {
                margin-left: 0.9375rem;
                display: inline-block;
                width: auto;
                height: auto;

            }
        }
    }
}
