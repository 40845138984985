// Standard Button
.button, button {
    display: inline-block;
    padding: 0.5rem 0.75rem;
    background: $primary-color;
    color: $white;
    font-family: $secondary-font;
    font-weight:700;
    text-transform: uppercase;
    text-decoration: none;
    outline: 0;
    border: 0;
    box-shadow: 0 0 8px rgba($black, 0.25);
    cursor: pointer;
    border-radius: 5px;

    &:hover {
        background: darken($primary-color, 10%);
        text-decoration: none;
        color: $white;
    }

    // Rounded Button
    &--rounded, button.rounded {
        border-radius: 5px;
        -webkit-border-radius: 5px;
    }

    &--secondary {
        @extend button;
        background: linear-gradient(to bottom, $secondary-color, #f06000, #e62b00);
        border: 3px solid $white;

        &:hover {
            background: $secondary-color;
        }

        @include breakpoint(large up) {
            font-size: 1.5rem;
        }
    }

    &--tertiary {
        color: $primary-color;
        background: $white;
        font-weight: bold;
    }
}
