/// Font size attribute applied to `<html>` and `<body>`. We use 100% by default so the value is inherited from the user's browser settings.
/// @type Number
$global-font-size: 100%;

/// Default line height for all type. `$global-lineheight` is 24px while `$global-font-size` is 16px
/// @type Number
$global-lineheight: 1.4;

/// Primary color for interactive components like links and buttons.
/// @type Color
$primary-color: #05767d;

/// Primary accent color for interactive components like links and buttons.
/// @type Color
$primary-accent-color: #a8dbde;

/// Secondary color, used with components that support the `.secondary` class.
/// @type Color
$secondary-color: #ff9301;

/// Secondary accent color, used with components that support the `.secondary` class.
/// @type Color
$secondary-accent-color: #e99c06;

/// Color used for light gray UI items.
/// @type Color
$light-gray: #e8e5e6;

/// Color used for medium gray UI items.
/// @type Color
$medium-gray: #878788;

/// Color used for dark gray UI items.
/// @type Color
$dark-gray: #231f20;

/// Color used for black ui items.
/// @type Color
$black: #000;

/// Color used for white ui items.
/// @type Color
$white: #fff;

/// Background color of the body.
/// @type Color
$body-background: $white;

/// Text color of the body.
/// @type Color
$body-font-color: $black;

/// Invalid color.
/// @type Color
$invalid-color: #ad6831;
$invalid-accent-color: #fcdb7f;

/// Success color.
/// @type Color
$success-color: $primary-color;
$success-accent-color: $primary-accent-color;

/// Font stack of the body.
/// @type List
$body-font-family: Verdana, Geneva, Tahoma, sans-serif;


$primary-font: 'PT Sans', 'Helvetica Neue', Arial, sans-serif;
$secondary-font: 'Archivo Narrow', 'Helvetica Neue', Arial, sans-serif;

/// Global value used for margin on components.
/// @type Number
$global-margin: 1rem;

/// Global value used for padding on components.
/// @type Number
$global-padding: 1rem;

/// Sizes of headings at various screen sizes. Each key is a breakpoint, and each value is a map of heading sizes.
/// @type Map
$header-sizes: (
  small: (
    'h1': 39,
    'h2': 22,
    'h3': 18.5,
    'h4': 16,
    'h5': 16,
    'h6': 16,
  ),39
  medium: (
    'h1': 55,
    'h2': 40,
    'h3': 32,
    'h4': 26,
    'h5': 20,
    'h6': 16,
  ),
);

/// Default Inset Box Shadow
$inset-box-shadow: inset 1px 4px 9px -6px $black;
