// Default Header Styles

#brand {
    display: block;
    padding: 1rem 0 0;
    background: $dark-gray;
    font-family: $secondary-font;

    // Logo
    #logo {
        @include grid-column(7);
        padding-left: 1rem;
        padding-bottom: 0.625rem;
        height: auto;

        @include breakpoint(medium) {
            @include grid-column(9);
        }

        img, span {
            display: block;

            @include breakpoint(medium) {
                 display: inline-block;
            }
        }

        img {
            margin-bottom: 0.75rem;
            max-width: 80%;

            @include breakpoint(medium) {
                margin-bottom: 0;
                max-width: 70%;
            }
        }

        span {
            font-size: 0.75rem;
            font-weight: 400;
            text-align: left;
            color: $medium-gray;

             @include breakpoint(medium) {
                 font-size: 1rem;
            }
        }
    }

    // Phone Number
    #conversions {
        @include grid-column(5);
        padding-right: 1rem;
        padding-bottom: 0.625rem;
        text-align: right;

        @include breakpoint(medium) {
            @include grid-column(3);
        }


        #directions {
            display: block;
            margin-bottom: 0.625rem;
            color: $medium-gray;
            font-size: 1rem;
            font-weight: 700;
            font-family: $primary-font;
            text-decoration: none;
            transition: all 240ms ease-in;
            -webkit-transition: all 240ms ease-in;
            -mz-transition: all 240ms ease-in;

            &:hover {
                color: lighten($light-gray, 10%);
            }
        }

        #contact-number {
            display: block;
            font-size: 1.5rem;
            font-weight: 700;
            color: $secondary-color;
            text-decoration: none;

            @include breakpoint(large up) {
                display: inline-block;
                vertical-align: middle;
                font-size: 2.5rem;
                line-height: 1;
            }
        }
    }
}

// Navigation
#primary-nav {
    @include clearfix;
    width: 100%;
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    background: $primary-color;

    @include breakpoint(medium up) {
        &:before {
            content:"";
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            width: 100%;
            background: linear-gradient(to bottom, darken($primary-color, 10%), $primary-color);
            height: 10px;
        }
    }

    ul {
        @include clearfix;
        margin: 0;
        padding: 0;
        list-style: none;

        @include breakpoint(medium only) {
            @include grid-column(12,0);
        }

        @include breakpoint(medium up) {
            float: right;
        }

        li {
            border-bottom: 1px solid $white;

            @include breakpoint(medium only) {
            }

            @include breakpoint(medium up) {
                border: none;
                float: left;
            }
            a {
                display: block;
                padding: 0.625rem 1.25rem;
                font-size: 1.25rem;
                font-weight: 700;
                font-family: $secondary-font;
                text-transform: uppercase;
                text-decoration: none;
                color: $white;
                transition: all 160ms ease-in;
                -webkit-transition: all 160ms ease-in;
                    -ms-transition: all 160ms ease-in;


                &:hover {
                    background: $secondary-color;
                }

                @include breakpoint(medium only) {
                    font-size: 1rem;
                    padding: 0.9375rem 1.5rem;

                }

                @include breakpoint(medium up) {
                    &:hover {
                        background: none;
                        box-shadow: inset 0 -6px 0 $secondary-color;
                    }
                }
            }
        }
    }
}

