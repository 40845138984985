// Default Footer Styles
footer {
    @include clearfix;
    display: block;
    width: 100%;
    float: left;
    margin: 0;
    background: $primary-accent-color url(../../img/utility-nav-bg.gif) top left repeat-x;

    // Utility Info Container
    #utility-info {
        padding: 0 0 1.25rem;
        @include clearfix;

        @include breakpoint(large up) {
            padding: 2.5rem 0 0;
        }

    }

    // Utility Navigation
    #utility-nav {
        @include grid-column(8);
        display: block;
        width: 100%;
        padding: 1.25rem 0;
        background: url(../../img/utility-nav-bg.gif) top left repeat-x;

        @include breakpoint(large up) {
            @include grid-column(5);
            @include grid-column-position(5);
            padding: 0;
            background: none;
        }

        ol {
            margin: 0;
            padding: 0;
            @include clearfix;
            list-style: none;

            li {
                float: left;
                width: 50%;
                padding: 0;
                text-align: center;
                margin-bottom: 0;

                @include breakpoint(medium only) {
                    @include grid-column(1 of 6, 0);
                    text-align: center;
                }

                @include breakpoint(medium up) {
                    margin-bottom: 0.9375rem;
                    width: auto;
                }

                a {
                    float: left;
                    margin-bottom: 0.625rem;
                    padding: 0 0.9375rem 0 0;
                    width: 100%;
                    font-size: 0.875rem;
                    font-weight: 400;
                    font-family: $primary-font;
                    text-align: center;
                    text-decoration: none;
                    color: $primary-color;

                    &:hover {
                        color: darken($primary-color, 10%);
                    }

                    @include breakpoint(medium only) {
                        text-align: center;
                        padding: 0;
                        width: 100%;
                    }

                    @include breakpoint(medium up) {
                        margin-bottom: 0;
                        font-size: 0.75rem;
                        width: auto;
                        text-align: left;
                    }
                }
            }
        }
    }

    // Utility Contact Info
    #contact-info {
        @include grid-column(12);
        margin-bottom: 1.25rem;
        padding: 0;
        text-align: center;
        color: #231f20;
        font-size: 0.875rem;
        font-weight: 400;
        font-family: $primary-font;
        line-height: 1.4;

         @include breakpoint(medium) {
             padding: 0;
        }

        @include breakpoint(large up) {
            @include grid-column(5);
            @include grid-column-position(-5);
            text-align: left;
        }

        .telephone {
            display: block;
            margin-bottom: 1.75rem;
            padding: 0.625rem 0;
            background: $primary-color;
            font-family: $secondary-font;
            font-weight: 700;
            font-size: 36px;
            color: white;

            @include breakpoint(large up) {
                @include grid-column(6);
                padding-top: 0;
                background: none;
                color: $primary-color;
                line-height: 1;
            }
        }

        div[itemprop="name"],
        span[itemprop="streetAddress"],
        span[itemprop="email"] {
            display: block;
            margin-bottom: 0.25rem;
            color: $dark-gray;
            text-decoration: none;
        }

        span[itemprop="email"] {
            margin-top: 0.25rem;
            font-weight: 700;
            color: $primary-color;
            text-decoration: none;
        }

        address {
            font-style: normal;

            @include breakpoint(large up) {
                @include grid-column(6);
            }
        }
    }

    // ELFA Membership
    .membership {
        @include grid-column(12);
        display: block;
        margin-bottom: 1.875rem;
        text-align: center;
        font-size: 0.75rem;
        color: $dark-gray;

        img {
            display: block;
            margin: 0 auto;
        }

        @include breakpoint(large up) {
            @include grid-column(2);
        }
    }

    // Copyright
    #copyright {
        @include grid-column(12);
        display: block;
        padding: 0.9875rem 0.625rem;
        background: rgba($white, 0.5);
        font-size: 0.625rem;
        text-align: center;
        font-style: normal;

        &:before {
            content:"";
        }

        @include breakpoint(large up) {
            background: none;
        }
    }
}
