@media print {
	a[href]:after {
	    content: none !important;
  	}
	form, #primary-nav, #directions, #utility-nav, .print-button, .telephone, #formButton {
		display: none !important;
	}
	.product-detail__left, .product-detail__right {
		width: 49%;
	}
}