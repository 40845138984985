// Breadcrumbs
#crumb-nav {
    display: none;
    @include breakpoint(medium up) {
        @include clearfix;
        display: block;
        background: $light-gray;
        border-bottom: 1px solid darken($light-gray, 10%);
        border-top: 1px solid darken($light-gray, 10%);
        ol {
            @include grid-column(12);
            @include clearfix;
            margin: 0;
            list-style: none;
            li {
                float: left;
                padding-right: 0.375rem;
                font-size: 0.875rem;
                &:after {
                    content: "/";
                    padding-left: 0.375rem;
                }
                a {
                    display: inline-block;
                    padding: 0.625rem 0;
                    text-decoration: none;
                }
            }
            li:last-child {
                font-weight: 700;
                &:after {
                    content: "";
                    text-indent: -9999rem;
                    font-size: 0;
                }
            }
        }
    }
}
