.note {
    @include callout-base;
    @include callout-style($primary-color);
    @include clearfix;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 0;
    padding: 0.25rem 0.625rem;
    font-size: 0.875rem;
    font-weight: 400;
    text-transform: capitalize;
    text-align: center;
    color: $primary-color;
    box-sizing: border-box;
    border-radius: 5px;
    .req {
        font-weight: 600;
        font-size: 1rem;
        vertical-align: text-top;
    }
    @include breakpoint(medium up) {
        margin: 0 1rem;
        text-align: right;
        width: calc(100% - 2rem);
    }
}

.form-container {
    @include grid-column(12);
    @include grid-column-end;
    margin-bottom: 3.125rem;
}

fieldset {
    @include clearfix;
    border: 0;
    padding: 0;
    background: transparent;
    legend {
        display: none;
        text-indent: -9999rem;
        font-size: 0;
    }
    ol {
        @include clearfix;
        margin: 0;
        padding: 0;
        list-style: none;
    }
}

label {
    font-weight: 700;
}

[type='text'], [type='password'], [type="file"],
[type='date'], [type='datetime'], [type='datetime-local'],
[type='month'], [type='week'], [type='email'], [type='number'],
[type='search'], [type='tel'], [type='time'], [type='url'],
[type='color'], textarea, select {
    border: 1px solid darken($light-gray, 10%);
    border-radius: 5px;
    width: 100%;
}

textarea {
    max-height: 240px !important;
}

.qrt {
    @include grid-column(12);
    @include breakpoint(medium up) {
        @include grid-column(3);
    }
}

.half {
    @include grid-column(12);
    @include breakpoint(medium up) {
        @include grid-column(6);
        @include grid-column-end();
    }
}

.full {
    @include grid-column(12);
}

button.button {
    display: block;
    margin: 0 0.625rem;
    font-size: 1.125rem;
    width: calc(100% - 1.25rem);
    height: 50px;
    border-radius: 5px;
    @include breakpoint(medium up) {
        margin-left: 0.9375rem;
        display: inline-block;
        width: auto;
        height: auto;
    }
}
