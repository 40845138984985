.our-inventory {

    #content {
        padding: 0;
    }
    p {
        @include grid-column(12);

        @include breakpoint(medium up) {
            @include grid-column-collapse;
        }
    }

    iframe {
        display: block;
        position: relative;
        margin: 2rem 0 3.125rem;
        padding: 0 0 1.875rem;
        width: 100%;
        height: 320px;
        border-top: 1px solid darken($light-gray, 10%);

        @include breakpoint(medium up) {
            height: 800px;
        }
    }

    // Overriding styles for eLift API
    #elift-container .btn {
        display: inline-block;
        padding: 0.5rem 0.75rem;
        background: $primary-color !important;
        color: $white !important;
        font-family: $secondary-font !important;
        font-weight:700;
        text-transform: uppercase;
        text-decoration: none;
        outline: 0;
        border: 0;
        box-shadow: 0 0 8px rgba($black, 0.25);
        cursor: pointer;
    }

    #elift-container #elift-result-container {
        padding-bottom: 100px;
    }
}


/* Product Listing */
.product-listing {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    @include breakpoint(large up) {
       flex-flow: row nowrap;
    }
}

.product-filters {
    flex-basis: auto;
    background-color: $medium-gray;
    margin-bottom: 16px;
    display: none;
    @include breakpoint(large up) {
        width: calc(25% - 25px);
        display: block;
    }
    &--open {
        display: block;
    }
    .filter {
        $filter-color: #228e95;

        &__legend {
            display: block;
            width: 100%;
            background: $dark-gray;
            text-indent: 0;
            font-size: inherit;
            color: $white;
            padding: 15px 22px;
            font-size: rem-calc(24);
            font-weight: bold;
        }
        &__input {
            color: $white;
            background: $medium-gray;
            border: 2px solid $white;
            margin: 5px 0;
            display: block;
            &--checkbox {
                display: none;
                margin: 0;
                & ~ .filter__label:before {
                    display: inline-block;
                    width: 10px;
                    content: '\f096';
                    font-family: 'fontawesome';
                    padding-right: 10px;
                }
                &:checked {
                    & ~ .filter__label:before {
                        content: '\f046';
                    }
                }
            }
            &--select {
                height: 2.4375rem;
                padding: 0.5rem;
                font-size: 1rem;
                font-family: inherit;
                line-height: normal;
                border-radius: 5;
                -webkit-appearance: none;
                -moz-appearance: none;
                background-size: 9px 6px;
                background-position: right -1rem center;
                -webkit-background-origin: content-box;
                background-origin: content-box;
                background-repeat: no-repeat;
                padding-right: 1.5rem;
                cursor: pointer;
            }
            &--split {
                width: 42.5%;
                float: left;
            }
        }
        &__label {
            color: $white;
            margin: 0;
            font-size: rem-calc(24);
            line-height: normal;
            &--checkbox {
                line-height: 1.4rem;
                font-weight: normal;
                font-size: rem-calc(16);
            }
            &--hidden {
                display: none;
            }
        }
        &__item {
            padding: 0 22px 20px 22px;
        }
        &__section {
            margin-bottom: 10px;
        }
        &__split {
            float: left;
            width: 15%;
            display: block;
            text-align: center;
            color: $white;
            font-size: rem-calc(16);
            padding-top: 6%;
        }
    }
    .button {
        width: 100%;
        margin-top: 20px;
        font-size: 1.25rem;
        color: $dark-gray;
    }
}

.filter-toggle {
    display: block;
    margin: 15px;
    cursor: pointer;
    font-size: rem-calc(20);
    @include breakpoint(large up) {
        display: none;
    }
    &--open {
        i {
            transform: rotate(90deg);
        }
    }
}

.product-results {
    @include breakpoint(large up) {
        padding: 0;
        width: calc(75% - 25px);
    }
}

.product-list {
    flex-basis: auto;
    margin: 0;
    padding: 0 15px;
    list-style: none;
    display: flex;
    flex-flow: row wrap;

    &__item {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        flex-basis: auto;

        border: 1px solid $light-gray;
        margin-bottom: 16px;

        border-radius: 5px;
        padding: 10px;
        width: 100%;
        @include breakpoint(medium only) {
            width: calc(50% - 7px);
            &:not(:nth-child(2n+2)) {
                margin-right: 14px;
            }
        }
        @include breakpoint(large up) {
            width: calc(33% - 7px);
            &:not(:nth-child(3n+3)) {
                margin-right: 14px;
            }
        }
    }
    &__name {
        color: $primary-color;
        border-bottom: 1px solid $light-gray;
        font-size: 1.25rem;
    }
    &__description {
        border-bottom: 1px solid $light-gray;
        padding-bottom: 10px;
    }
    &__price {
        color: $primary-color;
        font-size: rem-calc(20);
        padding: 10px 0;
    }
    &__button {
        width: 100%;
        text-align: center;
        font-size: rem-calc(20);
        margin-top: auto;
    }
    &__image {
        position: relative;
        margin-bottom: .9375rem;
        width: 100%;
        height: 160px;
        overflow: hidden;

        img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
        }

    }
}

.spec-list {
    margin: 10px 0 0 0;
    padding: 0 0 10px 0;
    list-style: none;
    border-bottom: 1px solid $light-gray;
}

.product-popup {
    @include flex-grid-row();
    flex-flow: column nowrap;
    justify-content: space-between;
    outline: 0;
    @include breakpoint(large up) {
        flex-flow: row wrap;
    }
    &__left {
        @include flex-grid-column(12,0);
        flex-basis: auto;
        @include breakpoint(large up) {
            @include flex-grid-column(6,0);
            background: $light-gray;
        }

        #prodLink, #prodLink:hover {
            text-decoration:  none;
        }
        .spec-list {
            @include flex-grid-row();
            &__item {
                @include flex-grid-column(12);
                @include breakpoint(large) {
                    @include flex-grid-column(6);
                }
            }
        }

        .product-list {
            &__price {
                padding-left: .9375rem;
            }
        }
        .product-popup__contact {
            padding: .9375rem;
        }
    }
    &__right {
        @include flex-grid-column(12,0);
        flex-basis: auto;
        width: 100%;
        @include breakpoint(large up) {
            @include flex-grid-column(6,0);
            padding-left: 1.25rem;
        }
    }
    &__image {
        width: 100%;
    }
    &__heading {
        background: $primary-color;
        color: $white;
        padding: 10px 15px;
        font-size: rem-calc(22);
        font-weight: normal;
        &:hover {
            text-decoration: none;
        }
    }
    &__close {
        display: block;
        width: 100%;
        justify-content: flex-end;
        text-align: right;
        margin-bottom: 10px;
        font-size: rem-calc(24);
    }
}

.popup-form {
    form {
        width: calc(100% + 32px);
        margin-left: -16px;
        margin-bottom: 1.25rem;
    }
    &__heading {
        color: $primary-color;
    }
}


.product-detail {
    @include flex-grid-row();
    margin-bottom: 40px;
    &__content {
        @include breakpoint(medium down) {
            padding: 0 10px;
            display: block;
        }
    }
    &__left {
        flex-basis: auto;
        width: 100%;
        @include breakpoint(large up) {
            @include flex-grid-column(7,0);
            padding: 20px;
            border: 1px solid $light-gray;
        }
    }
    &__right {
        flex-basis: auto;
        width: 100%;

        @include breakpoint(large up) {
            @include flex-grid-column(5,0);
            //background: $black;
        }
    }
    &__information {
        background-color: $dark-gray;
        color: $white;
    }
    &__image {
        width: 100%;
        &--thumb {
            width: 100%;
        }
    }
    &__imagelink {
        max-width: 20%;
        display: inline-block;
        padding-right: 5px;
    }
    &__gallery {
        display: flex;
        flex-flow: row wrap;
    }
    &__price {
        border-top: 1px solid $white;
        color: $primary-color;
        font-size: rem-calc(20);
        padding: 10px 0 40px 0;
    }
    &__specs {
        padding: 20px 20px 0 20px;
        color: $black;
        background-color: $light-gray;
    }
    &__contact-block {
        padding: 20px 20px 40px 20px;
        .product-detail__heading {
            color: $white;
            font-size: rem-calc(24);
        }
    }
}

.contact-number {
    background: $light-gray;
    padding: 20px;
    text-align: center;
    &__callout {
        font-size: rem-calc(28);
        color: $primary-color;
        margin-bottom: 0;
    }
    &__number {
        font-size: rem-calc(34);
        color: $dark-gray;
        font-weight: bold;
        margin-bottom: 0;
    }
}

.related-products {
    background: $dark-gray;
    color: $white;
}

.related-products {
    padding: 20px 0;
    &__heading {
        text-align: center;
        color: $white;
        position: relative;
        &:before, &:after {
            content: '';
            position: absolute;

            height: 1px;
            top: 50%;
            left: 5%;
            border-top: 1px solid $white;
            width: 20%;
            @include breakpoint(large up) {
                width: 30%;
            }
        }
        &:after {
            left: auto;
            right: 5%;
        }
    }
    .product-list {
        &__item {
            background-color: $white;
            color: $dark-gray;
        }
        &__button {
            display: none;
        }
    }
}

.print-container {
    margin: 10px 0;
    text-align: center;
    width: 100%;
    @include breakpoint(large up) {
        text-align: right;
    }
}